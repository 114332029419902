'use strict';

const activeClass = 'active',
      inActiveClass = 'inactive',
      showClass = 'show',
      hideClass = 'hide',
      btnMenu = '.btn-circle--menu',
      shareBlock = '.share',
      quizShare = '.quiz__share',
      nav = '.nav',
      navItem = '.nav__item';

function setAttr(elem, attr) {
  $(elem).each(function (i, it) {
    $(it).attr(attr, i);
  });
};

function showElemOnScroll(elem, top) {
  
  function showElem() {
    let scrollTop = $(window).scrollTop();

    if (scrollTop > top) {
      $(elem).addClass(showClass);
    } else {
      $(elem).removeClass(showClass);
    }
  };

  showElem();
  $(window).on('scroll', showElem);
};

const share = () => {
  const teaserShare = '.teaser__share',
        btnShare = '.btn-circle--share',
        header = '.header';

  function bindShare(trigger, share, blockHide) {

    $(trigger).on('click', function() {
      const _this = $(this),
            _btnMenu = _this.closest('.header').find(btnMenu);

      _this.toggleClass(activeClass);
      $(blockHide).toggleClass(hideClass);
      $(share).toggleClass(showClass);

      if($(btnMenu).length > 0) {
        $(_btnMenu).toggleClass(hideClass);
      }
    });
  };

  $(shareBlock).socialLikes({
    counters: false,
  });

  bindShare(`${teaserShare} ${btnShare}`, `${teaserShare} ${shareBlock}`, '.teaser__sign');
  bindShare(`${header} ${btnShare}`, `${header} ${shareBlock}`, `${header} ${nav}`);
  bindShare(`${quizShare} ${btnShare}`, `${quizShare} ${shareBlock}`, `${quizShare} .text`);
};

const map = () => {
  const teaserInfo = '.teaser__info',
        map = '.map',
        mapMain = '.map--main',
        navMap = '.nav-map',
        mapPoint = '.map__point';

  let svgMap = document.querySelector(`${mapMain} svg`);

  function onClickBtn() {
    $(`${teaserInfo} .btn`).on('click', function() {
      $(teaserInfo).hide();
      $('.teaser__nav').fadeIn();
      $(`${mapMain} ${mapPoint}`).show();
      ga("send", "event", "Click", "Button", "MapView_Click");
    });
  }

  function onEnterElem(wrap, trigger, elem) {
    $(wrap).on('mouseenter', trigger, function() {
      let id = $(this).attr('data-item'),
          current = $(`${elem}[data-item="${id}"]`);
      
      if(!current.hasClass('disable')) {
        current.addClass(activeClass);
      }
    });
  };

  function onLeaveElem(wrap, trigger, elem) {
    $(wrap).on('mouseleave', trigger, function() {
      $(elem).removeClass(activeClass);
    });
  }

  function paintMap() {
    if(svgMap) {
      let mapAnimate = new LazyLinePainter(svgMap, {
        strokeWidth : 3,
        strokeColor: '#0091da',
      });

      mapAnimate.on('complete', () => {
        $(svgMap).find('path').addClass(activeClass);
        onClickBtn();
      });

      mapAnimate.paint();
    }
  };

  setAttr(`${navMap} ${navItem}`, 'data-item');
  setAttr(mapPoint, 'data-item');
  setTimeout(() => {
    paintMap();
  }, 0);
  onEnterElem(navMap, navItem, mapPoint);
  onLeaveElem(navMap, navItem, mapPoint);
  onEnterElem(map, mapPoint, navItem);
  onLeaveElem(map, mapPoint, navItem);
};

const popup = () => {
  const body = 'body',
        popupAbout = '.popup-about',
        popupMenu = '.popup-menu',
        btnClose = '.btn-close';
  
  function bindPopup(trigger, popup, btnClose) {
    $(trigger).on('click', function(e) {
      e.preventDefault();
      $(popup).fadeIn().addClass(activeClass);
      $(body).addClass(inActiveClass);

      if($(this).hasClass('about-watch') && $(popupMenu).hasClass(activeClass)) {
        $(popupMenu).hide().removeClass(activeClass);
      }
    });
  
    $(btnClose).on('click', function() {
      $(popup).fadeOut().removeClass(activeClass);
      $(body).removeClass(inActiveClass);
    });
  };

  bindPopup('.about-watch', popupAbout, `${popupAbout} ${btnClose}`);
  bindPopup(btnMenu, popupMenu, `${popupMenu} ${btnClose}`);
};

const slider = () => {
  const slider = '.slider__list';
  
  function onClickBtnArrow(trigger, method) {
    $(trigger).on('click', function() {
      let _slider = $(this).closest('.slider').find(slider);
      _slider.slick(method);
    });
  };

  $(slider).slick({
    adaptiveHeight: true,
    arrows: false,
    dots: true,
    lazyLoad: 'ondemand',
    speed: 700,
    slidesToShow: 1,
  });

  onClickBtnArrow('.btn-arrow--next', 'slickNext');
  onClickBtnArrow('.btn-arrow--prev', 'slickPrev');
};

const animationPage = () => {
  AOS.init({
    startEvent: 'DOMContentLoaded',
    easing: 'ease-in-out',
    duration: 1400,
    once: true,
  });
};

const quiz = () => {
  
  const data = [
    {
      bg: 'img_001',
      question: 'Вам нужно пройти по лесному маршруту. Как будете ориентироваться на местности?',
      answers: [
          'Возьму бумажную карту – ничего надежней нет',
          'Зачем бумага? Что за пережитки прошлого. Положу в рюкзак хороший навигатор.',
          'И то, и другое.'
      ],
      correctAnswer: 3,
      desc: 'Верно брать с собой и бумажную карту, и навигатор. Ведь даже в инструкции к GPS-навигатору написано, что прибор нельзя использовать как единственную систему навигации.',
    },
    {
      bg: 'img_002',
      question: 'Возьмете с собой огниво, чтобы разжигать костер?',
      answers: [
          'Зажигалка это хорошо, но огниво надежнее.',
          'Что за ерунда – это же какое-то устаревшее слово из сказок Андерсена?',
          'Возьму отличную зажигалку. Эта модель не гаснет на ветру.'
      ],
      correctAnswer: 1,
      desc: 'Как показывает практика, иногда даже самые разрекламированные зажигалки (те, которые не гаснут на ветру и способны гореть во время дождя) подводят. Огниво – надежный и ничуть не устаревший вариант.',
    },
    {
      bg: 'img_003',
      question: 'Вещи, которые должны быть всегда с вами, если выходите из походного лагеря?',
      answers: [
          'Вода и кусок хлеба.',
          'Фонарь и нож.',
          'Телефон и зарядка.'
      ],
      correctAnswer: 2,
      desc: 'Всегда берите с собой фонарь и нож – даже если вышли из лагеря на 5 минут. Заблудиться можно и в трех соснах, а там и солнце село: нужно быть готовым к неожиданностям.',
    },
    {
      bg: 'img_004',
      question: 'Какой нож вы возьмете в поход?',
      answers: [
          'Главное – большое (в пределах закона) лезвие. Чтобы и колбасу порезать, ну и сосну на розжиг постругать.',
          'Свой любимый, с которым уже много раз ходил.',
          'Возьму «мультитул».'
      ],
      correctAnswer: 3,
      desc: 'Логичнее всего будет взять большой «мультитул» с длинным ножом: там будут и кусачки, отвертка, шило – все это может пригодиться в походе.',
    },
    {
      bg: 'img_005',
      question: 'Брать ли с собой в поход часы?',
      answers: [
          'Зачем часы? Время всегда можно посмотреть на мобильном телефоне.',
          'Да, обязательно. Но часы нужны хорошие, крепкие и со множеством полезных функций.',
          'Лучше взять планшет – нам нем и карту удобно смотреть, и время показывает.'
      ],
      correctAnswer: 2,
      desc: 'Качественные часы – то, что нужно в походе. Например, смарт-часы HONOR Watch GS Pro. Они оснащены емким аккумулятором, с которым устройство автономно функционирует до 25 дней. Имеют износостойкий корпус, способны противостоять перепадам давления и температуры, загрязнениям, ударам, а также длительному нахождению под солнечными лучами и водой.<span>HONOR Watch GS Pro оснащены рядом незаменимых технологий и функций, в числе которых построение обратного маршрута. Благодаря точным системам спутниковой навигации поиск обратной дороги станет возможным даже при путешествии по неизвестной местности.</span>',
    },
    {
      bg: 'img_006',
      question: 'Спальник, который вы возьмете с собой в летний поход, должен быть...',
      answers: [
          'Самым теплым, чтобы ночью не простудиться.',
          'Недорогим, подходящим для ночевок при температуре от +5.',
          'Красивым – чтобы на фотографиях получался хорошо.'
      ],
      correctAnswer: 2,
      desc: 'Как советуют наши эксперты, летом можно не покупать дорогой спальник, рассчитанный на самые суровые минусовые температуры, – вам в нем будет жарко. Если поход летний и несложный, то вполне можно рассматривать бюджетные и простые варианты, сэкономить на бренде: разницы вы почти не заметите.',
    },
    {
      bg: 'img_007',
      question: 'Без каких вещей нельзя идти в поход?',
      answers: [
          'Мобильный телефон с хорошей камерой – чтобы было что выкладывать потом в соцсети.',
          'Аптечка.',
          'Баллончик от медведя. Вдруг придет в лагерь?'
      ],
      correctAnswer: 2,
      desc: 'Если говорить о средней полосе России, то медведей здесь мало, а, кроме того, хищники и сами стараются держаться подальше от человека. Фотографии из похода – это прекрасно, но вот без чего точно нельзя идти в поход – аптечка (а в ней – жаропонижающее, средства от головной боли, расстройств ЖКТ и все, чтобы остановить кровь и обеззаразить рану).',
    },
    {
      bg: 'img_008',
      question: 'Каким должен быть походный костер?',
      answers: [
          'Главное – большим, чтобы давал больше тепла и отпугивал диких зверей. Значит, нужно как можно больше дров и большая площадка.',
          'Он должен просто быть – разжег, уже хорошо.',
          'Для костра нужно выкопать яму пошире и разводить огонь уже в ней.'
      ],
      correctAnswer: 3,
      desc: 'Костер правильно разводить в яме: это и защита от ветра, и гарантия того, что пламя не перекинется на ближайшие кусты.',
    },
    {
      bg: 'img_009',
      question: 'Что нужно знать, когда идешь по лесу?',
      answers: [
          'Главное – время обеда и ужина.',
          'Походные песни (чтобы не заскучать).',
          'Время рассвета, заката и погодные условия'
      ],
      correctAnswer: 3,
      desc: 'Внезапный ливень может спутать все ваши планы. Кроме того, необходимо знать время до наступления темноты: чтобы вовремя выбрать место для лагеря и установить его. Тут вам снова поможет HONOR Watch GS Pro. Часы способны прогнозировать смену погоды и предупреждать владельца. Умные часы также вычисляют время восхода и захода солнца, а также определяют приливы и отливы. Помимо этого, HONOR Watch GS Pro также идентифицирует 8 лунных фаз и оценивает время восхода и захода Луны (это важно для рыбаков).',
    },
    {
      bg: 'img_010',
      question: 'Ставить палатку у воды – отличная идея?',
      answers: [
          'Это может быть опасно!',
          'Да, можно быстрее набрать воды для готовки.',
          'У воды ставить можно, а вот на болоте не нужно.'
      ],
      correctAnswer: 1,
      desc: 'Бывает, что уровень реки поднимается буквально за ночь – лагерь смывает. Вокруг палатки также не должно быть сухих деревьев, которые свалятся на голову при сильном ветре. Еще одно правило: не стоит ставить палатку на звериных тропах, по которым животные ходят на водопой.',
    },
  ];

  const flow = [
    init,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderNextQuestion,
    showAnswer,
    renderResult,
    reset
  ];

  let currentStep = -1,
      currentQuestion,
      correctAnswers;

  const sectionQuiz = $('.section-bg--quiz'),
        quiz = $('.quiz'),
        quizIntro = $('.quiz__intro'),
        quizContent = $('.quiz__content'),
        quizResult = $('.quiz__result'),
        questionTitle = $('.question__title'),
        questionAnswers = $('.answers'),
        questionDesc = $('.question__desc'),
        resultText = $('.result__text'),
        numCorrect = $('.result__num-correct'),
        numTotal = $('.result__num-total'),
        btnStartClass = '.btn-start',
        btnNextClass = '.btn-next',
        btnContClass = '.btn-continue',
        btnAgainClass = '.btn-again',
        btnNext = $(btnNextClass),
        btnCont = $(btnContClass),
        quizShareBlock = $(`${quizShare} ${shareBlock}`),
        shareTg = $('.quiz__share .telegram');

  function flawCallNext(){
    flow[++currentStep]();
  };

  function init(){
    currentQuestion = -1;
    correctAnswers = 0;

    quizIntro.hide();
    quizResult.hide();
    quizContent.show();
    // sectionQuiz.css('background-image', 'url(/static/img/content/bg/quiz/img_007.jpg)');

    flawCallNext();
  };

  function renderNextQuestion(){
    btnNext.hide();
    btnCont.prop('disabled', true).show();
    currentQuestion++;

    sectionQuiz.css('background-image', `url(/static/img/content/bg/quiz/${data[currentQuestion].bg}.jpg)`);
    questionTitle.text(`${currentQuestion + 1}. ${data[currentQuestion].question}`);
    questionAnswers.html('');
    questionDesc.text('').hide();

    let template = '';

    for (let i = 0; i < data[currentQuestion].answers.length; i++) {
      template += `
        <p class='answers__item' position='${i + 1}'>
          ${data[currentQuestion].answers[i]}
        </p>
      `;
    }
    questionAnswers.append(template);
    quizContent.hide().fadeIn();
  };

  function showAnswer() {
    btnCont.hide();
    btnNext.show();

    const _this = $('.answers__item.active'),
          _answers = _this.parent().find(".answers__item:not(.active)"),
          position = +_this.attr("position"),
          correctAnswer = data[currentQuestion].correctAnswer,
          desc = data[currentQuestion].desc;

    _answers.remove();

    if (position === correctAnswer) {
      correctAnswers += 1;
      questionDesc.html(`Правильно! ${desc}`).fadeIn();
    } else {
      questionDesc.html(`Неправильно! ${desc}`).fadeIn();
    }
  };

  function renderResult(){
    quizContent.hide();
    sectionQuiz.css('background-image', 'url(/static/img/content/bg/quiz/img_011.jpg)');

    numCorrect.text(correctAnswers);
    numTotal.text(data.length);

    let url;

    switch (correctAnswers) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        resultText.text('Ваших знаний о путешествиях с палаткой слишком мало. Пожалуй, для начала вам лучше отправиться в поход с кем-то более опытным.');
        url = 'https://honorwatch.nat-geo.ru/quiz/results/result-bad/';
        break;
      case 5:
      case 6:
      case 7:
      case 8:
        resultText.text('Неплохо! Некоторый походный опыт у вас уже есть, но еще поучиться не помешает. Кстати, в качестве дополнительного помощника в походе, рекомендуем часы HONOR Watch GS Pro – они выстраивают маршрут по GPS, контролируют ваши физические показатели (пульс, уровень кислорода в крови),  вычисляют время восхода и захода солнца (важный момент для безопасности, ну и для эффектной съемки эта информация тоже нужна), а еще они водонепроницаемые и противоударные.');
        url = 'https://honorwatch.nat-geo.ru/quiz/results/result-normal/';
        break;
      default:
        resultText.text('Похоже, вы не раз были в походах и прекрасно знаете, как вести себя в условиях дикой природы. В качестве дополнительного помощника, рекомендуем часы HONOR Watch GS Pro. Они умеют выстраивать маршрут по GPS, контролируют ваши физические показатели (пульс, уровень кислорода в крови), вычисляют время восхода и захода солнца (важный момент для безопасности, ну и для эффектной съемки эта информация тоже нужна), а еще это водонепроницаемые и противоударные часы.');
        url = 'https://honorwatch.nat-geo.ru/quiz/results/result-good/';
        break;
    }

    shareTg.attr('href', `tg://msg_url?url=${url}&amp;text=Время движения. Хорошо ли ты знаешь крым?`);
    quizShareBlock.socialLikes({
        url: url,
        counters: false,
    });
    quizResult.fadeIn();
    ga("send", "event", "Click", "Test", "End_Test");
  };

  function reset(){
    currentStep = -1;
    flawCallNext();
  };

  quiz.on(
    'click',
    [
      btnStartClass,
      btnContClass,
      btnNextClass,
      btnAgainClass
    ].join(','),
    function(){
      flawCallNext();

      if($(this).hasClass('btn-start')) {
        ga("send", "event", "Click", "Test", "Start_Test");
      }

      if($(this).hasClass('btn-again')) {
        ga("send", "event", "Click", "Test", "Retry_Test");
      }
    }
  );

  quiz.on('click', '.answers__item:not(.disable)', function() {
    
    const _this = $(this),
          _answers = _this.parent().find(".answers__item");

    _answers.removeClass(activeClass);
    _this.addClass(activeClass);
    btnCont.prop('disabled', false);
  });

  $(document).on('keydown', function(e){
    if (e.keyCode === 13 && btnCont.prop('disabled') === false) {
      flawCallNext();
    }
  });
};

const btnScrollTop = () => {
  const btn = '.btn-go-top';
  
  $(btn).on('click', function() {
    $('html, body').animate({
      scrollTop: $('body').offset().top
    }, 'slow');
  });

  showElemOnScroll(btn, 1387);
};

const headerFixed = () => {
  const header = '.header',
        height = $('.section-bg--route').outerHeight() || 200;

  showElemOnScroll(header, height);
};

const eventsGA = () => {
  $('.logo').on('click', function() {
    ga("send", "event", "Click", "Client", "Logo_Click");
  });

  $('.learn-more').on('click', function() {
    ga("send", "event", "Click", "Client", "Aside_Click");
  });

  $('.link-watch').on('click', function() {
    ga("send", "event", "Click", "Client", "Link_Click");
  });

  $(nav).on('click', navItem, function() {
    ga("send", "event", "Click", "Button", "NavLink_Click");
  });

  $('.quiz__share .share__item').on('click', function() {

    let shareClass = $(this).attr('class').split(' ')[1];

    switch (shareClass) {
      case 'share__item--tw':
        ga("send", "event", "Click", "Test", "Share_TW");
        console.log(shareClass);
        break;
      case 'share__item--vk':
        ga("send", "event", "Click", "Test", "Share_VK");
        console.log(shareClass);
        break;
      case 'share__item--ok':
        ga("send", "event", "Click", "Test", "Share_OK");
        console.log(shareClass);
        break;
      case 'share__item--fb':
        ga("send", "event", "Click", "Test", "Share_FB");
        console.log(shareClass);
        break;
      case 'share__item--tg':
        ga("send", "event", "Click", "Test", "Share_TG");
        console.log(shareClass);
        break;
    }
  });
};

$(function () {
  share();
  map();
  popup();
  slider();
  animationPage();
  quiz();
  btnScrollTop();
  headerFixed();
  eventsGA();
});

